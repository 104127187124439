<template>
    <div id="orders">
        <article id="wrap">
            <AppHeader theme="white">AI マネージャー</AppHeader>
            <section class="">
                <div class="tab_wrap ai-chat-container">

                    <div class="chat-container" ref="messageContainer">
                        <div class="">
                            <div class="d-flex">
                                <div class="chat-ai-icon"></div>
                                <div class="font-bole answer-name">AIマネージャー</div>
                            </div>
                            <p>こんにちは。AIマネージャーです。<br>まだまだ未熟ですが、よろしくお願いいたします。<br>知りたいことを入力してください。</p>
                        </div>
    
                        <!-- チャット内容をここに入れます -->
                        <div v-for="(message, index) in messages" :key="index" :class="{'user-message': message.sender === 'user', 'ai-message': message.sender === 'ai'}">
                            <div v-if="message.sender === 'user'" class="d-flex">
                                <div class="chat-user-icon"></div>
                                <div class="font-bole answer-name">あなた</div>
                            </div>
                            <div v-else class="d-flex">
                                <div class="chat-ai-icon"></div>
                                <div class="font-bole answer-name">AIマネージャー</div>
                            </div>
                            <p>{{ message.content }}</p>
                        </div>
                        <!-- ローディングインジケータ -->
                        <div v-if="isLoading" class="loading-container">
                            <div class="loading-spinner"></div>
                        </div>
                    </div>

                    <div class="chat-form">
                        <div class="input-container">
                            <textarea v-model="userMessage" :disabled="isLoading" rows="4" placeholder="メッセージを入力して下さい..." />
                        </div>
                        <div class="button-container">
                            <div class="reset-button">
                                <button @click="resetChat">リセット</button>
                            </div>
                            <div class="send-button">
                                <button @click="sendChat" :disabled="isLoading">送信</button>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </article>
    </div>
</template>
  
<script>
import AppHeader from '@/components/AppHeader';
import axios from 'axios';
export default {
    name: 'MyPage',
    components: {AppHeader},
    computed: {
        loginUser () {
            return this.$store.state.loginUser;
        },
    },
    data () {
        return {
            userMessage: '',
            messages: [],
            isLoading: false,
        }
    },

    created() {},

    updated() {
        this.scrollToBottom();
    },

    methods: {
        async sendChat() {
            if (this.userMessage !== '') {
                this.isLoading = true;

                this.messages.push({
                    content: `${this.userMessage}`,
                    sender: 'user'
                });

                console.log(process.env.VUE_APP_API_ROOT)

                console.log(window.location.origin)

                let response = null;
                if (window.location.origin == 'http://localhost:8080') {
                    response = await axios.post('http://localhost:8080/api/chatGpt', {
                        prompt: this.userMessage,
                        user_id: this.loginUser.id,
                    });
                } else if (window.location.origin == 'http://room333.heuron.net') {
                    response = await axios.post('http://room333.heuron.net/api/chatGpt', {
                        prompt: this.userMessage,
                        user_id: this.loginUser.id,
                    });
                } else if (window.location.origin == 'https://sutekinasekai.net') {
                    response = await axios.post('https://sutekinasekai.net/api/chatGpt', {
                        prompt: this.userMessage,
                        user_id: this.loginUser.id,
                    });
                }

                this.messages.push({
                    content: `${response.data.message}`,
                    sender: 'ai'
                });

                this.isLoading = false;

                this.userMessage = '';
            }
        },
        resetChat() {
            this.userMessage = '';
            this.messages = [];
        },
        scrollToBottom() {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
        }
    }
}
</script>

<style>
#orders {
    height: 100vh;

}
.tab_wrap.ai-chat-container {
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    height: 88vh;
}
.tab_wrap .chat-container {
    overflow: scroll;
    overflow-x: hidden;
    margin-top: 20px;
}
@media screen and (max-width: 768px) {
    .tab_wrap.ai-chat-container {
        padding: 15px 20px;
        height: 90vh;
    }
}
.chat-form {
    margin-top: auto;
}

.input-container {
    display: flex;
    background-color: #fff;
}
.input-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 30px;
    margin-right: 10px;
}
.input-container button {
    padding: 10px; 
    background-color: rgb(80, 78, 78);
    color: white;
    border: none;
    border-radius: 50%; 
    cursor: pointer;
    height: 50%;
    margin-top: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.reset-container {
    display: flex;
    padding-left: 10px;
    background-color: #fff;
}

.reset-button button {
    background-color: #30b2d6; /* 背景色 */
    color: white; /* 文字色 */
    border: none; /* ボーダー無し */
    padding: 10px; /* パディング */
    border-radius: 5px; /* 角の丸み */
    cursor: pointer; /* カーソルをポインタに */
    width: 300px;
}
@media screen and (max-width: 768px) {
    .reset-button button {
        width: 150px;
    }
}

.send-button button {
    background-color: black; /* 背景色 */
    color: white; /* 文字色 */
    border: none; /* ボーダー無し */
    padding: 10px; /* パディング */
    border-radius: 5px; /* 角の丸み */
    cursor: pointer; /* カーソルをポインタに */
    width: 300px;
}
@media screen and (max-width: 768px) {
    .send-button button {
        width: 150px;
    }
}

.user-message {
}

.ai-message {
}
.font-bole {
    font-weight: bold;
}
.chat-user-icon, .chat-ai-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    margin-right: 10px;
}
.chat-user-icon {
    background-color: #ff7f50;
}
.chat-ai-icon {
    background-color: #30b2d6;
}
.d-flex {
    display: flex;
} 

/* ローディングインジケータのスタイル */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* 必要に応じてサイズや位置を調整 */
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #000;
    animation: spin 1s linear infinite;
}
.answer-name {
    margin-top: 4px;
}

/* ローディングアニメーション */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>